import React from 'react';
import { useNavigate } from 'react-router-dom';

import { fullReset } from '../../api/fullReset';
import { imageCacheService } from '../../services/preloadService';
import { CLOUD_STORAGE_KEYS, ROUTES } from '../../utils/constants';
import { cloudRemoveItem } from '../../utils/cloudStorage';

import Popup from '../PopUp/PopUp';
import styles from './ResetPopUp.module.css';

const ResetPopUp = ({ isOpen, onClose, setIsLoading }) => {
  const navigate = useNavigate();

  const handleKeepSubscription = async () => {
    try {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('light');

      // Выполняем полный сброс приложения
      await fullReset();

      // Выполняем полный сброс кэша изображений
      await imageCacheService.fullReset();

      // Удаляем данные из облачного хранилища
      await Promise.all([
        cloudRemoveItem(CLOUD_STORAGE_KEYS.is_created),
        cloudRemoveItem(CLOUD_STORAGE_KEYS.is_offer_confirmed),
      ]);

      // Закрываем попап
      onClose();

      // Включаем экран загрузки
      setIsLoading(true);

      // Переходим на главную страницу
      navigate(ROUTES.home);
    } catch (error) {
      console.error('Reset error:', error);
      // В случае ошибки все равно пытаемся перезагрузить приложение
      setIsLoading(true);
      navigate(ROUTES.home);
    }
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <h2 className={styles.title}>
        Вы уверены, что хотите сделать полный сброс?
      </h2>
      <p className={styles.description}>
        Это очистит ваш кэш, локальную историю, удалит подписку и сделает полный
        сброс профиля
      </p>
      <div className={styles.buttons}>
        <button
          className={styles.keepButton}
          onClick={() => {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
            onClose();
          }}
        >
          Отмена
        </button>
        <button
          className={styles.cancelButton}
          onClick={handleKeepSubscription}
        >
          Сделать сброс
        </button>
      </div>
    </Popup>
  );
};

export default ResetPopUp;
