import React, { useState } from 'react';

import CachedImage from '../CachedImage/CachedImage';

import styles from './ProductItem.module.css';

const ProductItem = ({ product, onClick, loading }) => {
  const [imageLoaded, setImageLoaded] = useState(true);

  return (
    <div className={styles.productItem} onClick={() => onClick(product)}>
      {(!imageLoaded || loading) && (
        <div
          className={`${styles.productLoader} ${loading ? styles.loading : ''}`}
        >
          <div
            className={
              loading ? `${styles.loader} ${styles.loading}` : styles.loader
            }
          ></div>
        </div>
      )}
      <CachedImage
        src={
          product?.images?.[0]
            ? `${product.images[0]}`
            : `assets/why_beauty.webp`
        }
        alt={product.title}
        className={`${styles.productImage} ${imageLoaded ? styles.loaded : ''}`}
        onLoad={() => setImageLoaded(true)}
      />
    </div>
  );
};

export default ProductItem;
