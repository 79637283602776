const BASE_URL = process.env.REACT_APP_SERVICE_URL;

const conversationEndpoints = {
  startConversation: '/api/start-conversation',
};

export const startConversation = (message) => {
  const token = JSON.parse(localStorage.getItem('Token'));

  fetch(`${BASE_URL}${conversationEndpoints.startConversation}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token.value,
    },
    body: JSON.stringify({
      query_id: window.Telegram.WebApp.initDataUnsafe.query_id,
      message,
    }),
    keepalive: true,
  });
};

// import makeRequest from './apiConfig';

// const conversationEndpoints = {
//   startConversation: '/api/start-conversation',
// };

// export const startConversation = async (message) => {
//   try {
//     const response = await makeRequest({
//       method: 'POST',
//       endpoint: conversationEndpoints.startConversation,
//       body: JSON.stringify({
//         query_id: window.Telegram.WebApp.initDataUnsafe.query_id,
//         message,
//       }),
//     });

//     return response;
//   } catch (error) {
//     console.error('Authentication failed:', error);
//     throw error;
//   }
// };
