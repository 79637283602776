import React, { useEffect, useState } from 'react';

import { stories } from '../../Config';

import { sendGTMEvent } from '../../utils/analytics';
import { GA4_KEY } from '../../utils/constants';

import StoriesItem from '../StoriesItem/StoriesItem';
import CachedImage from '../CachedImage/CachedImage';

import styles from './StoriesContainer.module.css';

const StoriesContainer = () => {
  const [selectedStory, setSelectedStory] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [remainingTime, setRemainingTime] = useState(3000);
  const [pressStartTime, setPressStartTime] = useState(null);

  // Timer effect for auto-progression
  useEffect(() => {
    let timer;

    if (selectedStory && !isPaused) {
      setStartTime(Date.now());
      timer = setTimeout(() => {
        if (currentIndex < selectedStory.content.length - 1) {
          setCurrentIndex(currentIndex + 1);
          setRemainingTime(3000); // Reset for next story
        } else {
          closeStory();
        }
      }, remainingTime);
    } else if (isPaused && startTime) {
      // Calculate remaining time when paused
      const elapsed = Date.now() - startTime;
      const newRemainingTime = Math.max(0, remainingTime - elapsed);
      setRemainingTime(newRemainingTime);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    // eslint-disable-next-line
  }, [selectedStory, currentIndex, isPaused]);

  useEffect(() => {
    if (selectedStory) {
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.setHeaderColor('#000');
      window.Telegram.WebApp.BackButton.onClick(() => {
        window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
        window.Telegram.WebApp.setHeaderColor('#fff0f5');
        setSelectedStory(null);
        window.Telegram.WebApp.BackButton.hide();
      });
    } else {
      window.Telegram.WebApp.BackButton.hide();
    }
  }, [selectedStory]);

  const openStory = (story) => {
    window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
    setSelectedStory(story);
    setCurrentIndex(0);
    setIsPaused(false);
    setRemainingTime(3000);
    setStartTime(Date.now());
  };

  const closeStory = () => {
    window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
    window.Telegram.WebApp.setHeaderColor('#fff0f5');
    setSelectedStory(null);
    setIsPaused(false);
  };

  const nextStory = () => {
    if (selectedStory && currentIndex < selectedStory.content.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setRemainingTime(3000); // Reset timer for new story
      setStartTime(Date.now());
    } else {
      closeStory();
    }
  };

  const prevStory = () => {
    if (selectedStory && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setRemainingTime(3000); // Reset timer for new story
      setStartTime(Date.now());
    }
  };

  const handleTouchStart = (e) => {
    // Проверяем, не началось ли касание на кнопке CTA
    const target = e.target;
    if (target.closest(`.${styles.storyCta}`)) {
      return;
    }

    setTouchStartX(e.touches[0].clientX);
    setIsPaused(true);
  };

  const handleTouchEnd = (e) => {
    // Проверяем, не закончилось ли касание на кнопке CTA
    const target = e.target;
    if (target.closest(`.${styles.storyCta}`)) {
      return;
    }

    const touchEndX = e.changedTouches[0].clientX;
    if (touchStartX - touchEndX > 50) {
      if (selectedStory && currentIndex < selectedStory.content.length - 1) {
        nextStory();
      } else {
        closeStory();
      }
    } else if (touchStartX - touchEndX < -50) {
      if (selectedStory && currentIndex > 0) {
        prevStory();
      } else {
        closeStory();
      }
    }
    setIsPaused(false);
  };

  const handleScreenMouseDown = (e) => {
    // Проверяем, не началось ли нажатие на кнопке CTA
    const target = e.target;
    if (target.closest(`.${styles.storyCta}`)) {
      return;
    }

    setIsPaused(true);
    setPressStartTime(Date.now());
  };

  const handleScreenMouseUp = (e) => {
    // Проверяем, не закончилось ли нажатие на кнопке CTA
    const target = e.target;
    if (target.closest(`.${styles.storyCta}`)) {
      return;
    }

    setIsPaused(false);
    const pressDuration = Date.now() - pressStartTime;

    if (pressDuration < 200) {
      const screenWidth = window.innerWidth;
      const clickX = e.clientX;
      const thirdWidth = screenWidth / 3;

      window.Telegram.WebApp.HapticFeedback.impactOccurred('light');

      if (clickX < thirdWidth) {
        // Left third - previous story
        prevStory();
      } else if (clickX > thirdWidth * 2) {
        // Right third - next story
        nextStory();
      }
      // Middle third (thirdWidth <= clickX <= thirdWidth * 2) - do nothing
    }

    setPressStartTime(null);
  };

  const handleCtaClick = (e, story) => {
    // Останавливаем все возможные события
    e.preventDefault();
    e.stopPropagation();

    // Предотвращаем обработку любых других событий мыши
    setIsPaused(true);
    setPressStartTime(null);

    window.Telegram.WebApp.HapticFeedback.impactOccurred('light');

    sendGTMEvent(GA4_KEY.buy_product(story.link, story.price, story.cta));

    // Небольшая задержка перед открытием ссылки, чтобы гарантировать, что все события обработаны
    setTimeout(() => {
      window.Telegram.WebApp.openLink(story.link, {
        try_instant_view: true,
      });
    }, 50);
  };

  return (
    <div>
      <div className={styles.storiesContainer}>
        {stories.map((story) => (
          <StoriesItem key={story.id} story={story} openStory={openStory} />
        ))}
      </div>

      {selectedStory && (
        <div
          className={styles.storyModal}
          onMouseDown={handleScreenMouseDown}
          onMouseUp={handleScreenMouseUp}
          onMouseLeave={() => {
            setIsPaused(false);
            setPressStartTime(null);
          }}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          <div className={styles.storyHeader}>
            <div className={styles.storyUserInfo}>
              <CachedImage
                src='assets/klava_logo.webp'
                alt='Klava Golman'
                className={styles.storyUserAvatar}
              />
              <span className={styles.storyUsername}>klavagolman</span>
            </div>
            <button
              className={styles.storyClose}
              onClick={(e) => {
                e.stopPropagation();
                closeStory();
              }}
            >
              &times;
            </button>
          </div>

          <div className={styles.storyNavigation}>
            {selectedStory.content.map((_, index) => (
              <div
                key={`${index}-${currentIndex}`}
                className={`${styles.storyNavItem} ${
                  currentIndex === index ? styles.active : ''
                } ${isPaused && currentIndex === index ? styles.paused : ''} ${
                  index < currentIndex ? styles.viewed : ''
                }`}
              />
            ))}
          </div>

          <div className={styles.storyContent}>
            <CachedImage
              src={`${selectedStory.content[currentIndex].image}`}
              alt={selectedStory.content[currentIndex].description}
              className={styles.storyFullImage}
            />
            <div className={styles.storyFooter}>
              <p className={styles.storyDescription}>
                {selectedStory.content[currentIndex].description}
              </p>
              <p className={styles.storyPrice}>
                {selectedStory.content[currentIndex].price}
              </p>
              {selectedStory.content[currentIndex].cta && (
                <div
                  className={styles.ctaWrapper}
                  onMouseDown={(e) => e.stopPropagation()}
                  onMouseUp={(e) => e.stopPropagation()}
                  onTouchStart={(e) => e.stopPropagation()}
                  onTouchEnd={(e) => e.stopPropagation()}
                >
                  <button
                    className={styles.storyCta}
                    onClick={(e) =>
                      handleCtaClick(e, selectedStory.content[currentIndex])
                    }
                  >
                    {selectedStory.content[currentIndex].cta}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StoriesContainer;
