import makeRequest from './apiConfig';

const fullRestEndpoints = {
  reset: '/api/full-reset',
};

export const fullReset = async () => {
  try {
    await makeRequest({
      method: 'DELETE',
      endpoint: fullRestEndpoints.reset,
    });
  } catch (error) {
    console.error('Authentication failed:', error);
    throw error;
  }
};
