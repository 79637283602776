import { cloudSetItem } from '../utils/cloudStorage';
import { CLOUD_STORAGE_KEYS } from '../utils/constants';

import makeRequest from './apiConfig';

const authEndpoints = {
  auth: '/api/app-auth',
  create: '/api/create-user',
  setPhoto: '/api/photo-url',
};

export const getAuth = async (userData) => {
  try {
    const response = await makeRequest({
      method: 'POST',
      endpoint: authEndpoints.auth,
      body: JSON.stringify({
        user_id: userData.id,
        username: userData.username || 'unknown',
      }),
    });

    return response;
  } catch (error) {
    console.error('Authentication failed:', error);
    throw error;
  }
};

export const createUser = async (userData) => {
  const urlParams = new URLSearchParams(window.location.search);
  let referer_id = urlParams.get('tgWebAppStartParam');
  if (!referer_id) {
    referer_id = null;
  }
  try {
    const fullname = userData.last_name
      ? `${userData.first_name} ${userData.last_name}`
      : userData.first_name;
    await makeRequest({
      method: 'POST',
      endpoint: authEndpoints.create,
      body: JSON.stringify({
        user_id: userData.id,
        fullname,
        referer_id,
        username: userData.username,
        ...userData,
      }),
    });
    cloudSetItem(CLOUD_STORAGE_KEYS.is_created, true);
  } catch (error) {
    if (error.status === 409) {
      cloudSetItem(CLOUD_STORAGE_KEYS.is_created, true);
      console.log('User already exists:', error);
    } else {
      console.error('Error occurred while creating user:', error);
      throw error;
    }
  }
};

export const setPhoto = async (userData) => {
  try {
    await makeRequest({
      method: 'PUT',
      endpoint: authEndpoints.setPhoto,
      body: JSON.stringify({ photo_url: userData.photo_url }),
    });
  } catch (error) {
    // if (error.status === 405) {
    console.log(
      'Set photo request failed with 405 status (Method Not Allowed). Skipping.'
    );
    // } else {
    //   console.error('Error occurred while sending photo:', error);
    //   throw error;
    // }
  }
};
