import React, { useEffect, useState } from 'react';
import { imageCacheService } from '../../services/preloadService';

const CachedImage = ({ src, alt, className, ...props }) => {
  const isExternalUrl = (url) =>
    url.startsWith('http://') || url.startsWith('https://');

  const [imageSrc, setImageSrc] = useState(() => {
    if (isExternalUrl(src)) {
      return src;
    }
    return imageCacheService.getLocalCachedUrl(src) || src;
  });

  useEffect(() => {
    let isMounted = true;

    const loadImage = async () => {
      if (isExternalUrl(src)) {
        setImageSrc(src);
        return;
      }

      if (!imageCacheService.needsLoading(src)) {
        const cachedUrl = imageCacheService.getLocalCachedUrl(src);
        if (cachedUrl && isMounted) {
          setImageSrc(cachedUrl);
        }
        return;
      }

      try {
        const cachedUrl = await imageCacheService.getCachedImage(src);
        if (isMounted) {
          setImageSrc(cachedUrl);
        }
      } catch (error) {
        console.warn(`Failed to load cached image ${src}:`, error);
      }
    };

    loadImage();

    return () => {
      isMounted = false;
    };
  }, [src]);

  // Обработчики для предотвращения контекстного меню и выделения
  const preventDefault = (e) => {
    e.preventDefault();
    return false;
  };

  return (
    <img
      src={imageSrc}
      alt={alt}
      className={className}
      onContextMenu={preventDefault}
      onMouseDown={preventDefault}
      draggable='false'
      style={{
        WebkitUserSelect: 'none',
        userSelect: 'none',
        WebkitTouchCallout: 'none',
        pointerEvents: 'none',
      }}
      {...props}
    />
  );
};

export default CachedImage;
