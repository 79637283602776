import React from 'react';
import { Link } from 'react-router-dom';

import { startConversation } from '../../api/conversation';

import { sendGTMEvent } from '../../utils/analytics';
import { GA4_KEY, URL_BOT, ROUTES } from '../../utils/constants';

import styles from './ProfileActions.module.css';

const ProfileActions = () => {
  const openAIChat = () => {
    const tg = window.Telegram?.WebApp;
    const isTdesktop = tg.platform === 'tdesktop';

    window.Telegram.WebApp.setHeaderColor('#1D2733');
    window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
    startConversation('Привет!');
    if (!tg?.initDataUnsafe?.query_id) {
      tg.openTelegramLink(URL_BOT);
    }
    document.querySelector('.App').classList.add('fadeOut');
    sendGTMEvent(GA4_KEY.open_ai_chat);
    if (isTdesktop) {
      setTimeout(() => {
        tg.close();
      }, 350);
    } else {
      tg.close();
    }
  };

  return (
    <div className={styles.profileActionsContainer}>
      <div className={styles.profileActions}>
        <Link
          to={ROUTES.communities}
          className={styles.profileButton}
          onClick={() => {
            sendGTMEvent(GA4_KEY.open_communities);
            window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
          }}
        >
          <i className='fas fa-comment-dollar'></i>Сообщества
        </Link>
        <button
          className={styles.profileButton}
          onClick={() => {
            openAIChat();
            // window.Telegram.WebApp.close();
          }}
        >
          <i className='fas fa-envelope'></i>Чат с Klava
        </button>
      </div>
      <Link
        to={ROUTES.subscription}
        state={{ from: ROUTES.home }}
        className={styles.profileButton}
        onClick={() => {
          sendGTMEvent(GA4_KEY.open_subscription);
          window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
        }}
      >
        Подпискa
      </Link>
    </div>
  );
};

export default ProfileActions;
