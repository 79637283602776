import makeRequest from './apiConfig';

const beautyQuestionsEndpoints = {
  beautyQuestions: '/api/beauty-questions',
};

export const sendBeautyQuestions = async (data) => {
  try {
    const response = await makeRequest({
      method: 'PUT',
      endpoint: beautyQuestionsEndpoints.beautyQuestions,
      body: JSON.stringify(data),
    });

    return response;
  } catch (error) {
    console.error('Sending beauty questions failed:', error);
    throw error;
  }
};
