// BeautyQuestions.jsx
import React, { useState, useEffect } from 'react';

import { sendBeautyQuestions } from '../../api/beautyQuestions';
import { startConversation } from '../../api/conversation';

import { BEAUTY_QUESTIONS, URL_BOT } from '../../utils/constants';

import styles from './BeautyQuestions.module.css';

const BeautyQuestions = () => {
  const [answers, setAnswers] = useState({});
  const [otherAnswer, setOtherAnswer] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    window.Telegram.WebApp.BackButton.hide();
    window.Telegram.WebApp.setHeaderColor('#fff');
    return () => {
      window.Telegram.WebApp.BackButton.onClick = () => {};
      window.Telegram.WebApp.BackButton.hide();
    };
  }, []);

  const questionWithOther =
    'Какие проблемы с основной длиной волос у тебя есть?';

  const adjustTextareaHeight = (textarea) => {
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    }
  };

  useEffect(() => {
    const textarea = document.querySelector(`.${styles.otherInput}`);
    adjustTextareaHeight(textarea);
  }, [otherAnswer]);

  const handleSingleSelect = (question, value) => {
    setAnswers((prev) => ({
      ...prev,
      [question]: [value],
    }));
    setErrors((prev) => ({
      ...prev,
      [question]: false,
    }));
  };

  const handleMultiSelect = (question, value) => {
    setAnswers((prev) => {
      const currentAnswers = prev[question] || [];

      if (
        value === 'Ничего из этого' ||
        value === 'Ничем' ||
        value === 'Ничего'
      ) {
        return {
          ...prev,
          [question]: [value],
        };
      }

      if (question === questionWithOther) {
        if (value === 'Другое') {
          if (currentAnswers.includes('Другое')) {
            setOtherAnswer('');
            return {
              ...prev,
              [question]: currentAnswers.filter(
                (a) =>
                  BEAUTY_QUESTIONS[question].answers.includes(a) &&
                  a !== 'Другое'
              ),
            };
          } else {
            return {
              ...prev,
              [question]: [...currentAnswers, 'Другое'],
            };
          }
        }
      }

      const standardAnswers = currentAnswers.filter(
        (a) => a !== 'Ничего из этого' && a !== 'Ничем' && a !== 'Ничего'
      );

      if (standardAnswers.includes(value)) {
        return {
          ...prev,
          [question]: standardAnswers.filter((a) => a !== value),
        };
      }

      return {
        ...prev,
        [question]: [...standardAnswers, value],
      };
    });

    setErrors((prev) => ({
      ...prev,
      [question]: false,
    }));
  };

  const handleOtherInput = (e) => {
    const value = e.target.value;
    if (value.length <= 124) {
      setOtherAnswer(value);
      adjustTextareaHeight(e.target);
      setAnswers((prev) => {
        const currentAnswers = (prev[questionWithOther] || []).filter(
          (a) =>
            a === 'Другое' ||
            BEAUTY_QUESTIONS[questionWithOther].answers.includes(a)
        );
        return {
          ...prev,
          [questionWithOther]: value
            ? [...currentAnswers, value]
            : currentAnswers,
        };
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    Object.keys(BEAUTY_QUESTIONS).forEach((question) => {
      if (!answers[question] || answers[question].length === 0) {
        newErrors[question] = true;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);

      // Find first error element and scroll to it
      setTimeout(() => {
        const firstErrorElement = document.querySelector(`.${styles.error}`);
        if (firstErrorElement) {
          firstErrorElement.parentElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }, 0);

      return;
    }

    try {
      const tg = window.Telegram?.WebApp;
      const isTdesktop = tg.platform === 'tdesktop';
      await sendBeautyQuestions(answers);
      startConversation('Бьюти-диагностика пройдена! Какие результаты?');
      if (!tg?.initDataUnsafe?.query_id) {
        tg.openTelegramLink(URL_BOT);
      }
      document.querySelector('.App').classList.add('fadeOut');
      if (isTdesktop) {
        setTimeout(() => {
          tg.close();
        }, 350);
      } else {
        tg.close();
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Произошла ошибка при отправке формы');
    }
  };

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit} className={styles.form}>
        {Object.entries(BEAUTY_QUESTIONS).map(([question, data]) => (
          <div key={question} className={styles.questionBlock}>
            <h3 className={styles.questionTitle}>{question}</h3>
            <div className={styles.answersContainer}>
              {data.answers.map((answer) => (
                <div key={answer} className={styles.answerItem}>
                  <input
                    type={data.isSingleSelect ? 'radio' : 'checkbox'}
                    id={`${question}-${answer}`}
                    name={`question-${question}`}
                    value={answer}
                    checked={answers[question]?.includes(answer) || false}
                    onChange={() => {
                      if (data.isSingleSelect) {
                        handleSingleSelect(question, answer);
                      } else {
                        handleMultiSelect(question, answer);
                      }
                    }}
                    className={styles.input}
                  />
                  <label
                    htmlFor={`${question}-${answer}`}
                    className={styles.label}
                  >
                    {answer}
                  </label>
                </div>
              ))}
              {question === questionWithOther &&
                answers[question]?.includes('Другое') && (
                  <textarea
                    value={otherAnswer}
                    onChange={handleOtherInput}
                    placeholder='Опишите вашу проблему (макс. 124 символа)'
                    className={styles.otherInput}
                    maxLength={124}
                    rows={1}
                  />
                )}
            </div>
            {errors[question] && (
              <p className={styles.error}>
                Это поле обязательно для заполнения
              </p>
            )}
          </div>
        ))}
        <button type='submit' className={styles.submitButton}>
          Отправить
        </button>
      </form>
    </div>
  );
};

export default BeautyQuestions;
