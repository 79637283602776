import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { sendGTMEvent } from '../../utils/analytics';
import { GA4_KEY, ROUTES } from '../../utils/constants';

import SubscriptionInformation from '../../components/SubscriptionInformation/SubscriptionInformation';
import SubscriptionMenu from '../../components/SubscriptionMenu/SubscriptionMenu';

import styles from './Subscription.module.css';

const Subscription = ({
  widget,
  subscriptionInfo,
  setSubscriptionInfo,
  subscriptionHash,
  setSubscriptionHash,
  isHaveSubscriptionHash,
  setProductId,
  setIsLoading,
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [isShowPaymentsHistory, setIsShowPaymentsHistory] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);

  const {
    history,
    price,
    user_data: {
      notify_subscription,
      notify_updates,
      premium,
      subscription_expires_at,
      subscription_active,
    },
  } = subscriptionInfo;

  const handleBackButton = () => {
    window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');

    if (isPaymentOpen) {
      widget.close();
      setIsPaymentOpen(false);
      return;
    }

    if (isShowPaymentsHistory) {
      setIsShowPaymentsHistory(false);
      return;
    }

    if (isDrawerOpen) {
      setIsDrawerOpen(false);
      return;
    }

    if (isResetModalOpen) {
      setIsResetModalOpen(false);
      return;
    }

    if (isHaveSubscriptionHash) {
      navigate(ROUTES.home);
      return;
    }

    if (state?.from.includes('products')) {
      const id = state?.from.split('/').pop();
      setProductId(+id);
    }
    navigate(state?.from || ROUTES.home);
  };

  useEffect(() => {
    sendGTMEvent(GA4_KEY.navigate(window.location.href));

    window.Telegram.WebApp.BackButton.show();
    window.Telegram.WebApp.setHeaderColor(
      isPaymentOpen
        ? '#222D42'
        : isShowPaymentsHistory
        ? '#fff'
        : isDrawerOpen || isCancelModalOpen || isResetModalOpen
        ? '#7F7F7F'
        : '#fff'
    );
    window.Telegram.WebApp.BackButton.onClick(handleBackButton);

    return () => {
      window.Telegram.WebApp.BackButton.hide();
      window.Telegram.WebApp.BackButton.offClick(handleBackButton);
    };
    // eslint-disable-next-line
  }, [
    isDrawerOpen,
    isPaymentOpen,
    isShowPaymentsHistory,
    isCancelModalOpen,
    isResetModalOpen,
  ]);

  return (
    <div className={styles.container}>
      {premium ? (
        <SubscriptionInformation
          premium={premium}
          nextPaymentDate={subscription_expires_at}
          price={price}
          subscriptionNotify={notify_subscription}
          contentNotify={notify_updates}
          history={history}
          setSubscriptionInfo={setSubscriptionInfo}
          setIsDrawerOpen={setIsDrawerOpen}
          isDrawerOpen={isDrawerOpen}
          setIsShowPaymentsHistory={setIsShowPaymentsHistory}
          isShowPaymentsHistory={isShowPaymentsHistory}
          isCancelModalOpen={isCancelModalOpen}
          setIsCancelModalOpen={setIsCancelModalOpen}
          subscriptionActive={subscription_active}
          isResetModalOpen={isResetModalOpen}
          setIsResetModalOpen={setIsResetModalOpen}
          setIsLoading={setIsLoading}
        />
      ) : (
        <SubscriptionMenu
          widget={widget}
          premium={premium}
          price={price}
          setSubscriptionInfo={setSubscriptionInfo}
          subscriptionHash={subscriptionHash}
          setSubscriptionHash={setSubscriptionHash}
          setIsPaymentOpen={setIsPaymentOpen}
        />
      )}
    </div>
  );
};

export default Subscription;
