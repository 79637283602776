import React, { useEffect, useState } from 'react';

import { startConversation } from '../../api/conversation';
import { createPayment, getPaymentStatus } from '../../api/payments';
import { getSubscriptionInfo } from '../../api/subscription';

import { sendGTMEvent } from '../../utils/analytics';
import { GA4_KEY, SUBSCRIPTION_PERIOD, URL_BOT } from '../../utils/constants';
import { initializeWidget } from '../../utils/cloudpayments-init';

import SubscriptionLoader from '../SubscriptionLoader/SubscriptionLoader';
import SubscriptionMenuItem from '../SubscriptionMenuItem/SubscriptionMenuItem';
import Popup from '../PopUp/PopUp';
import CachedImage from '../CachedImage/CachedImage';

import styles from './SubscriptionMenu.module.css';

const subscriptionFeatures = [
  {
    title: 'Цифровая Клава на связи 24/7',
    description:
      'Всегда рядом, чтобы ответить на любые вопросы, поддержать и дать совет 💕',
  },
  {
    title: 'Уникальные гайды и советы',
    description:
      'Собрала для тебя самые полезные материалы и лайфхаки от меня и проверенных экспертов 🎓',
  },
  {
    title: 'Бьюти-диагностика',
    description: 'Определим твой тип кожи и волос 💇‍♀️',
  },
  {
    title: 'Рекомендации продуктов',
    description:
      'Подберу для тебя только те средства, которые реально работают 🤝',
  },
  {
    title: 'Напоминания от Бьюти Суеты',
    description:
      'Вместе внедрим уход в рутину! Буду поддабривать и подбадривать на каждом этапе 💪',
  },
  {
    title: 'Еженедельные Бьюти Таро',
    description:
      'Заглянем в будущее красоты и посмотрим, какие советы пригодятся на следующую неделю 🔮',
  },
  {
    title: 'Клава-рулетка',
    description:
      'каждый месяц случайным образом выбираю подписчика и записываю видео-ответ на вопрос! 💖',
  },
  {
    title: 'И другие сюрпризы каждый месяц 🎁',
    description: '',
  },
];

const SubscriptionMenu = ({
  premium,
  price,
  widget,
  setSubscriptionInfo,
  subscriptionHash,
  setSubscriptionHash,
  setIsPaymentOpen,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openErrorPopup, setOpenErrorPopup] = useState(false);

  // Обработчик платежа
  const handlePayment = async (hash) => {
    // console.log('Starting payment process with hash:', hash);
    setIsPaymentOpen(true);

    try {
      const result = await initializeWidget(widget, price, hash, setIsLoading);
      // console.log('Widget initialization result:', result);

      if (result.success) {
        const status = await getPaymentStatus(hash);
        // console.log('Payment status:', status);

        if (status.status === 'closed') {
          const subscription = await getSubscriptionInfo();
          setSubscriptionInfo(subscription);
        } else {
          setOpenErrorPopup(true);
        }
      }
    } catch (error) {
      console.error('Payment error:', error);
      setOpenErrorPopup(true);
    } finally {
      setSubscriptionHash(null);
      setIsLoading(false);
      setIsPaymentOpen(false);
    }
  };

  // Эффект для обработки существующего хэша
  useEffect(() => {
    if (subscriptionHash) {
      // console.log('Processing existing hash:', subscriptionHash);
      handlePayment(subscriptionHash);
    }
    // eslint-disable-next-line
  }, [subscriptionHash]);

  // Обработчик клика по кнопке подписки
  const handleSubscriptionClick = async () => {
    window.Telegram.WebApp.HapticFeedback.impactOccurred('light');

    if (premium) {
      sendGTMEvent(GA4_KEY.manage_subscription);
    } else {
      try {
        sendGTMEvent(GA4_KEY.buy_subscription);

        const orderDetails = await createPayment(SUBSCRIPTION_PERIOD);
        // console.log('Created payment order:', orderDetails);

        if (orderDetails.hash) {
          setSubscriptionHash(orderDetails.hash);
        }
      } catch (error) {
        console.error('Payment creation error:', error);
        setOpenErrorPopup(true);
      }
    }
  };

  return (
    <>
      {isLoading && <SubscriptionLoader />}
      <div className={styles.subscriptionHeader}>
        <CachedImage
          src='assets/subscriptionLogoBack.svg'
          className={styles.logoBack}
          alt='Фон подписки'
        />
        <CachedImage
          src='assets/subscriptionLogoImage.svg'
          className={styles.logo}
          alt='Логотип подписки'
        />
      </div>

      <div className={styles.subscriptionContent}>
        <div className={styles.contentTitle}>
          <h2>Klavagolman</h2>
          <h2>Plus</h2>
        </div>
        <div className={styles.featuresList}>
          {subscriptionFeatures.map((feature) => (
            <SubscriptionMenuItem key={feature.title} feature={feature} />
          ))}
        </div>
      </div>

      <div className={styles.subscriptionFooter}>
        <button
          className={styles.subscribeButton}
          onClick={handleSubscriptionClick}
        >
          {premium ? 'Управление подпиской' : `Подписаться за ${price} ₽/месяц`}
        </button>

        <button
          className={styles.chatButton}
          onClick={() => {
            const tg = window.Telegram?.WebApp;
            if (tg) {
              const isTdesktop = tg.platform === 'tdesktop';

              window.Telegram.WebApp.setHeaderColor('#1D2733');
              window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
              startConversation('Привет! Рассказажи мне про подписку!');

              if (!tg.initDataUnsafe?.query_id) {
                tg.openTelegramLink(URL_BOT);
              }

              document.querySelector('.App').classList.add('fadeOut');
              sendGTMEvent(GA4_KEY.open_ai_chat);
              if (isTdesktop) {
                setTimeout(() => {
                  tg.close();
                }, 350);
              } else {
                tg.close();
              }
            }
          }}
        >
          Задать вопрос в чате
        </button>
      </div>

      <Popup isOpen={openErrorPopup} onClose={() => setOpenErrorPopup(false)}>
        <h2 className={styles.errorPopupTitle}>
          Оплата прошла неудачно
          <br />
          или вы закрыли окно оплаты
        </h2>
        <p className={styles.errorPopupDescription}>
          Пожалуйста, попробуйте еще раз или обратитесь в службу поддержки
        </p>
        <div className={styles.errorPopupButtons}>
          <button
            className={styles.keepButton}
            onClick={() => {
              const tg = window.Telegram?.WebApp;
              const isTdesktop = tg.platform === 'tdesktop';

              window.Telegram.WebApp.setHeaderColor('#1D2733');
              window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
              startConversation('Привет! У меня проблемы с оплатой!');
              if (!tg?.initDataUnsafe?.query_id) {
                tg.openTelegramLink(URL_BOT);
              }
              document.querySelector('.App').classList.add('fadeOut');
              window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
              setOpenErrorPopup(false);
              sendGTMEvent(GA4_KEY.open_ai_chat);
              if (isTdesktop) {
                setTimeout(() => {
                  tg.close();
                }, 350);
              } else {
                tg.close();
              }
            }}
          >
            Обратиться в службу поддержки
          </button>
          <button
            className={styles.cancelButton}
            onClick={() => {
              window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
              setOpenErrorPopup(false);
            }}
          >
            Закрыть
          </button>
        </div>
      </Popup>
    </>
  );
};

export default SubscriptionMenu;
