import React, { useEffect, useRef, useState } from 'react';

import { sendGTMEvent } from '../../utils/analytics';
import { GA4_KEY, PRODUCT_CATEGORIES } from '../../utils/constants';

import styles from './ContentToggle.module.css';

const ContentToggle = ({ activeCategory, setActiveCategory }) => {
  const [isFixed, setIsFixed] = useState(false);
  const toggleRef = useRef(null);
  const placeholderRef = useRef(null);

  useEffect(() => {
    const toggle = toggleRef.current;
    const placeholder = placeholderRef.current;

    if (!toggle || !placeholder) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsFixed(!entry.isIntersecting);
      },
      {
        threshold: 0.8,
      }
    );

    observer.observe(placeholder);

    return () => observer.disconnect();
  }, []);

  const handleToggle = (content) => {
    setActiveCategory(content);
  };

  return (
    <>
      {/* Placeholder element for intersection observer */}
      <div ref={placeholderRef} className={styles.placeholder} />

      <div
        ref={toggleRef}
        className={`${isFixed ? styles.contentToggleFixed : ''} ${
          styles.contentToggle
        }`}
      >
        <button
          className={`${styles.contentToggleButton} ${
            activeCategory === PRODUCT_CATEGORIES.highlights.key
              ? styles.active
              : ''
          }`}
          onClick={() => {
            sendGTMEvent(
              GA4_KEY.choose_category(PRODUCT_CATEGORIES.highlights.value)
            );
            window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
            handleToggle(PRODUCT_CATEGORIES.highlights.key);
          }}
        >
          {PRODUCT_CATEGORIES.highlights.value}
        </button>
        {/* <button
          className={`${styles.contentToggleButton} ${
            activeCategory === 'products' ? styles.active : ''
          }`}
          onClick={() => {
            sendGTMEvent(GA4_KEY.choose_category('товары'));
            window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
            handleToggle('products');
          }}
        >
          Товары
        </button> */}
        <button
          className={`${styles.contentToggleButton} ${
            activeCategory === PRODUCT_CATEGORIES.guides.key
              ? styles.active
              : ''
          }`}
          onClick={() => {
            sendGTMEvent(
              GA4_KEY.choose_category(PRODUCT_CATEGORIES.guides.value)
            );
            window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
            handleToggle(PRODUCT_CATEGORIES.guides.key);
          }}
        >
          {PRODUCT_CATEGORIES.guides.value}
        </button>
        {/* <button
          className={`${styles.contentToggleButton} ${
            activeCategory === PRODUCT_CATEGORIES.advice.key ? styles.active : ''
          }`}
          onClick={() => {
            sendGTMEvent(GA4_KEY.choose_category(PRODUCT_CATEGORIES.advice.value));
            window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
            handleToggle(PRODUCT_CATEGORIES.advice.key);
          }}
        >
          {PRODUCT_CATEGORIES.advice.value}
        </button> */}
      </div>

      {/* Spacer to prevent content jump when toggle becomes fixed */}
      {isFixed && (
        <div
          className={styles.spacer}
          style={{ height: `${toggleRef.current?.offsetHeight}px` }}
        />
      )}
    </>
  );
};

export default ContentToggle;
